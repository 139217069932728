<template>
  <section class="bg-white border-b py-8">
    <div class="container mx-auto flex flex-wrap pt-4 pb-12">
      <h1
        class="
          w-full
          my-2
          text-5xl
          font-bold
          leading-tight
          text-center text-gray-800
        "
      >
        Our Products
      </h1>
      <div class="w-full mb-4">
        <div
          class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"
        ></div>
      </div>
      <div
        v-for="product in products"
        :key="product"
        class="
          border-2
          transform
          transition
          duration-600
          hover:scale-110
          w-full
          md:w-1/3
          p-6
          flex flex-col flex-grow flex-shrink
        "
      >
        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
          <a href="#" class="flex flex-wrap no-underline hover:no-underline">
            <p class="w-full text-gray-600 text-xs md:text-sm px-6">
              {{ product.category }}
            </p>
            <div class="w-full font-bold text-xl text-gray-800 px-6">
              {{ product.name }}
            </div>
            <p class="text-gray-800 text-base px-6 mb-5">
              {{ product.description }}
            </p>
          </a>
        </div>
        <div
          class="
            flex-none
            mt-auto
            bg-white
            rounded-b rounded-t-none
            overflow-hidden
            shadow
            p-6
          "
        >
          <div class="flex items-center justify-start">
            <a
              :href="product.link"
              target="_blank"
              class="
                mx-auto
                lg:mx-0
                hover:underline
                gradient
                text-white
                font-bold
                rounded-full
                my-6
                py-4
                px-8
                shadow-lg
                focus:outline-none
                focus:shadow-outline
                transform
                transition
                hover:scale-105
                duration-300
                ease-in-out
              "
            >
              {{ product.linkText }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="js">
export default {
  name: "ProductComponent",
  data() {
    return {
      products: [
        {
          name: "Resume Builder",
          category: "Web",
          link: "https://github.com/alphasofthub/resume",
          linkText: "Visit",
          description: "Let's build your resume on GitHub page.\n",
        }
      ]
    }
  }
}
</script>
