<template>
  <section class="bg-white border-b">
    <div class="container max-w-5xl mx-auto m-8">
      <h1
        class="
          w-80
          my-2
          text-5xl
          font-bold
          leading-tight
          text-center text-gray-800
        "
      >
        Our Services
      </h1>
      <div class="w-full mb-4">
        <div
          class="h-1 mx-6 gradient w-64 opacity-25 my-0 py-0 rounded-t"
        ></div>
      </div>
      <div class="flex flex-wrap">
        <div
          class="
            w-5/6
            sm:w-1/2
            p-6
            services-hover
            transform
            transition
            duration-500
            hover:scale-110
          "
        >
          <div class="">
            <h4 class="text-2xl text-gray-800 font-bold leading-none mb-3">
              Applications
            </h4>
            <p class="text-gray-600 mb-8">
              We create applications that are well-designed and interactive,
              customised to suit your business needs. These apps are designed to
              run faster and connect with users, resulting in a great UI
              interface.
            </p>
          </div>
        </div>
        <div
          class="
            w-full
            sm:w-1/2
            p-6
            services-hover
            transform
            transition
            duration-500
            hover:scale-110
          "
        >
          <div>
            <h4 class="text-2xl text-gray-800 font-bold leading-none mb-3">
              Web Scrapping
            </h4>
            <p class="text-gray-600 mb-8">
              We specialise in building crawl clusters that crawl through large
              volumes of data from multiple sources, retrieving usable data more
              efficiently while breaking free of scaling constraints.
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap flex-col-reverse sm:flex-row">
        <div
          class="
            w-full
            sm:w-1/2
            p-6
            mt-6
            services-hover
            transform
            transition
            duration-500
            hover:scale-110
          "
        >
          <div>
            <h4 class="text-2xl text-gray-800 font-bold leading-none mb-3">
              Cloud Solutions
            </h4>
            <p class="text-gray-600 mb-8">
              Create, publish, and promote engaging content to generate more
              traffic and build a dedicated community.
            </p>
          </div>
        </div>
        <div
          class="
            w-full
            sm:w-1/2
            p-6
            mt-6
            services-hover
            transform
            transition
            duration-500
            hover:scale-110
          "
        >
          <div class="align-middle">
            <div>
              <h4 class="text-2xl text-gray-800 font-bold leading-none mb-3">
                Artificial Intelligence
              </h4>
              <p class="text-gray-600 mb-8">
                AI driven application increases, that helps us to archive the
                intelligence task, we’re specialise in deep learning, data
                science, reinforcement learning etc.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div
            class="
              w-5/6
              sm:w-1/2
              p-6
              services-hover
              transform
              transition
              duration-500
              hover:scale-110
            "
          >
            <div>
              <h4 class="text-2xl text-gray-800 font-bold leading-none mb-3">
                Quality Assurance
              </h4>
              <p class="text-gray-600 mb-8">
                Quality assurance plays a vital role in successful development
                of products, ensuring your product functions well and meets our
                quality standard without breaking the deadlines.
              </p>
            </div>
          </div>
          <div
            class="
              w-full
              sm:w-1/2
              p-6
              services-hover
              transform
              transition
              duration-500
              hover:scale-110
            "
          >
            <div>
              <h4 class="text-2xl text-gray-800 font-bold leading-none mb-3">
                Graphic Designing
              </h4>
              <p class="text-gray-600 mb-8">
                Graphics are required to make something look cool. we’re
                specialise in Logo, business card, Background Removal, photo
                editing etc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {},
});
</script>
