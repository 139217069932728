
import { defineComponent } from "vue";
import ServicesComponent from "@/components/ServicesComponent.vue";
import ProductComponent from "@/components/ProductComponent.vue";

export default defineComponent({
  name: "Home",
  components: { ServicesComponent, ProductComponent },
  mounted() {},
  methods: {},
});
