<template>
  <div>
    <Banner />
    <div class="bg-white dark:bg-black dark:text-white">
      <header
        x-data="{ mobileMenuOpen : false }"
        class="
          flex flex-wrap flex-row
          justify-between
          md:items-center
          md:space-x-4
          bg-white
          py-6
          px-6
          relative
          sticky
          top-0
          z-50
          animated
        "
        :class="{ 'shadow-lg': !atTopOfPage, gradient: atTopOfPage && isHome }"
      >
        <router-link class="block" :to="{ path: '/' }">
          <span class="sr-only">AlphaSoftHub</span>
          <img
            class=""
            src="./assets/Text.png"
            alt="Logo"
            width="50"
            height="50"
            title="Logo"
          />
        </router-link>
        <button
          @click="mobileMenuOpen = !mobileMenuOpen"
          class="inline-block md:hidden w-8 h-8 bg-gray-200 text-gray-600 p-1"
        >
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- <button
          class="inline-block md:hidden"
        >
        <AccountDropDown class="inline-block md:hidden w-8 h-8 text-gray-600 p-1"/>
        </button> -->

        <nav
          class="
            absolute
            md:relative
            top-16
            left-0
            md:top-0
            z-20
            md:flex
            flex-col
            md:flex-row
            md:space-x-6
            font-semibold
            w-full
            md:w-auto
            bg-white
            shadow-md
            rounded-lg
            md:rounded-none
            md:shadow-none
            md:bg-transparent
            p-6
            pt-0
            md:p-0
          "
          :class="{ flex: mobileMenuOpen, hidden: !mobileMenuOpen }"
          click="mobileMenuOpen = false"
        >
          <router-link
            class="block py-1 hover:text-black"
            :class="getLinkClass()"
            :to="{ path: '/' }"
          >
            <i class="fa">
              <img
                style="width: 0.75em; height: 0.75em"
                :src="getIcon('home')"
              />
            </i>
            Home
          </router-link>
          <router-link
            class="block py-1 hover:text-black"
            :class="getLinkClass()"
            :to="{ path: '/blogs' }"
          >
            <i class="fa">
              <img
                style="width: 0.75em; height: 0.75em"
                :src="getIcon('blog')"
              />
            </i>

            Blogs
          </router-link>
          <router-link
            class="block py-1 hover:text-black"
            :class="getLinkClass()"
            :to="{ path: '/services' }"
            ><i class="fa">
              <img
                style="width: 0.75em; height: 0.75em"
                :src="getIcon('services_1')"
              />
            </i>
            Services
          </router-link>
          <router-link
            class="block py-1 hover:text-black"
            :class="getLinkClass()"
            :to="{ path: '/products' }"
            ><i class="fa">
              <img
                style="width: 0.75em; height: 0.75em"
                :src="getIcon('products')"
              />
            </i>
            Products
          </router-link>
          <router-link
            class="block py-1 hover:text-black"
            :class="getLinkClass()"
            :to="{ path: '/apps' }"
            ><i class="fa">
              <img
                style="width: 0.75em; height: 0.75em"
                :src="getIcon('apps')"
              />
            </i>
            Professional Apps
          </router-link>
          <!-- <DotDropDown /> -->
          <!-- <AccountDropDown class="" /> -->

          <!--        <div class="theme-switch-wrapper">-->
          <!--          <span ref="toggleDark" class="h-6 w-6 flex items-center justify-center cursor-pointer bg-blue-500 dark:bg-green-500 rounded-full" @click="toggleDarkMode">☀️</span>-->
          <!--        </div>-->
        </nav>
      </header>

      <router-view />
      <hr />
      <footer class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap md:text-left text-center order-first">
            <div class="lg:w-1/3 md:w-1/2 w-full px-4">
              <h2
                class="
                  title-font
                  font-medium
                  text-gray-900
                  tracking-widest
                  text-sm
                  mb-3
                "
              >
                PRODUCTS
              </h2>
              <nav class="list-none mb-10">
                <router-link
                  class="text-gray-600 hover:text-gray-800"
                  :to="{ path: '/services' }"
                  ><i class="fa">
                    <img
                      style="width: 0.75em; height: 0.75em"
                      :src="require('@/assets/icons/services_1.svg')"
                    />
                  </i>
                  Services
                </router-link>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/products' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/products.svg')"
                      />
                    </i>
                    Products
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/apps' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/apps.svg')"
                      />
                    </i>
                    Professional Apps
                  </router-link>
                </li>
                <li>
                  <a class="text-gray-600 hover:text-gray-800" disabled
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/enterprise.svg')"
                      />
                    </i>
                    Enterprise services</a
                  >
                </li>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/status' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/status.svg')"
                      />
                    </i>
                    Status
                  </router-link>
                </li>
              </nav>
            </div>

            <div class="lg:w-1/3 md:w-1/2 w-full px-4">
              <h2
                class="
                  title-font
                  font-medium
                  text-gray-900
                  tracking-widest
                  text-sm
                  mb-3
                "
              >
                COMPANY
              </h2>
              <nav class="list-none mb-10">
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/about' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/about.svg')"
                      />
                    </i>
                    About Us
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/team' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/teams.svg')"
                      />
                    </i>
                    Our Team
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/contact' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/contact_us.svg')"
                      />
                    </i>
                    Contact Us
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/faq' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/faq.svg')"
                      />
                    </i>
                    FAQ
                  </router-link>
                </li>
              </nav>
            </div>
            <div class="lg:w-1/3 md:w-1/2 w-full px-4">
              <h2
                class="
                  title-font
                  font-medium
                  text-gray-900
                  tracking-widest
                  text-sm
                  mb-3
                "
              >
                LEGAL
              </h2>
              <nav class="list-none mb-10">
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/terms' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/terms.svg')"
                      />
                    </i>
                    Terms and condition
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/privacy' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/privacy.svg')"
                      />
                    </i>
                    Privacy Policy
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/refund' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/refund.svg')"
                      />
                    </i>
                    Refund Policy
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-gray-600 hover:text-gray-800"
                    :to="{ path: '/gdrp' }"
                    ><i class="fa">
                      <img
                        style="width: 0.75em; height: 0.75em"
                        :src="require('@/assets/icons/gdrp.svg')"
                      />
                    </i>
                    GDRP
                  </router-link>
                </li>
              </nav>
            </div>
          </div>
        </div>
        <div class="bg-gray-100">
          <div
            class="
              container
              px-5
              py-6
              mx-auto
              flex
              items-center
              sm:flex-row
              flex-col
            "
          >
            <a
              class="
                flex
                title-font
                font-medium
                items-center
                md:justify-start
                justify-center
                text-gray-900
              "
            >
              <img src="./assets/Text.png" width="50" height="50" />

              <span class="ml-3 text-xl">AlphaSoftHub</span>
            </a>
            <p class="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
              © 2021 AlphaSoftHub (Private) Limited
            </p>
            <span
              class="
                inline-flex
                sm:ml-auto
                sm:mt-0
                mt-4
                justify-center
                sm:justify-start
              "
            >
              <a
                href="https://github.com/alphasofthub"
                target="_blank"
                class="ml-3 text-gray-500"
              >
                <i class="fa">
                  <img
                    style="width: 0.8em; height: 0.8em"
                    :src="require('@/assets/icons/github.svg')"
                  />
                </i>
              </a>
              <a
                href="https://web.facebook.com/alphasofthub"
                target="_blank"
                class="ml-3 text-gray-500"
              >
                <i class="fa">
                  <img
                    style="width: 0.8em; height: 0.8em"
                    :src="require('@/assets/icons/facebook.svg')"
                  />
                </i>
              </a>
              <a
                href="https://twitter.com/alphasofthub"
                target="_blank"
                class="ml-3 text-gray-500"
              >
                <i class="fa">
                  <img
                    style="width: 0.8em; height: 0.8em"
                    :src="require('@/assets/icons/twitter.svg')"
                  />
                </i>
              </a>
              <a
                href="https://www.linkedin.com/company/75766397"
                target="_blank"
                class="ml-3 text-gray-500"
              >
                <i class="fa">
                  <img
                    style="width: 0.8em; height: 0.8em"
                    :src="require('@/assets/icons/linkedin.svg')"
                  />
                </i>
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
    <button
      v-if="top"
      class="backtotop bg-gray-300"
      v-on:click="scrollTop()"
      title="Go to top"
    >
      <i class="text-black fa">
        <img style="width: 0.75em;height: 0.75em;" :src="require('@/assets/icons/arrowup.svg')" />
      </i>
    </button>
  </div>
</template>

<style scoped>
nav {
  z-index: 10;
}

nav.scrolled {
  @apply shadow-2xl;
  border-bottom: 0px;
}
</style>
<script lang="js">
import AOS from 'aos'
import 'aos/dist/aos.css'
import Banner from "@/components/Banner";
//import AccountDropDown from "@/components/AccountDropDown.vue";
// import DotDropDown from "@/components/DotDropDown";

export default {
  name: 'app',
  components: {
    Banner
    //DotDropDown
  },
  watch: {
    $route(to, from) {
      const base = `AlphaSoftHub`
      let title = ""
      if (typeof to.meta.title === 'string')
        title = `${to.meta.title} - ${base}`
      else if (typeof to.meta.title === 'function')
        title = `${to.meta.title(to)} - ${base}`
      else title = base
      this.isHome = to.path === '/';

      document.title = title
    },
  },

  mounted() {
    this.scrollTop()
  },
  data() {
    return {
      top: false,
      nav: {},
      mobileMenuOpen: false,
      atTopOfPage: true,
      isHome: false,
      hidden: false,
    }
  },
  created() {
    AOS.init()
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    getIcon(icon) {
      if (this.mobileMenuOpen == false && this.atTopOfPage && this.isHome)
        return require("@/assets/icons/white/"+icon+".svg")
      else
        return require("@/assets/icons/"+icon+".svg")
    },
    getLinkClass() {
      if (this.mobileMenuOpen == false && this.atTopOfPage && this.isHome)
        return "text-white"
      else
        return "text-gradient" //"text-indigo-600"
    },
    toggleDarkMode(theme, evt) {
      let htmlElm = document.querySelector("html");
      const setLight = () => {
        htmlElm.classList.remove("dark");
        localStorage.theme = "light";
        this.$refs.toggleDark.textContent = "☀️";
      };
      const setDark = () => {
        htmlElm.classList.add("dark");
        localStorage.theme = "dark";
        this.$refs.toggleDark.textContent = "🌙";
      };

      if (typeof theme === "string") {
        if (theme === "dark") setDark();
        else setLight();
      } else {
        if (localStorage.theme === "dark") setLight();
        else setDark();
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    handleScroll(event) {
      if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
        this.top = true
      } else {
        this.top = false
      }
      if (window.pageYOffset > 0) {
        // user is scrolled
        if (this.atTopOfPage) this.atTopOfPage = false
      } else {
        // user is at top of page
        if (!this.atTopOfPage) this.atTopOfPage = true
      }
    },
  }
}
</script>
