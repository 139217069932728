import {RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Home"
    }
  },
  {
    path: '/blogs',
    name: 'Blog',
    component: () => import('../views/Blogs.vue'),
    meta: {
      title: "Blogs"
    }
  },
  {
    path: '/blog/view/:post_id',
    name: 'BlogView',
    component: () => import('../views/BlogView.vue'),
    meta: {
      title: "Blog"
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
    meta: {
      title: "Services"
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
    meta: {
      title: "Products"
    }
  },
  {
    path: '/blog/view',
    name: 'BlogView',
    component: () => import('../views/BlogView.vue'),
    meta: {
      title: "Blog Title"
    }
  },
  {
    path: "/team",
    name: "Team",
    component: () => import('../views/site/team.vue'),
    meta: {
      title: "Our Team"
    }
  },
  {
    path: "/apps",
    name: "Apps",
    component: () => import('../views/Apps.vue'),
    meta: {
      title: "Our Apps"
    }
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import('../views/site/Contact.vue'),
    meta: {
      title: "Contact Us"
    }
  },
  {
    path: "/about",
    name: "About",
    component: () => import('../views/site/About.vue'),
    meta: {
      title: "About Us"
    }
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import('../views/site/Faq.vue'),
    meta: {
      title: "FAQ"
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import('../views/site/Terms.vue'),
    meta: {
      title: "Terms of Service"
    }
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import('../views/site/Privacy.vue'),
    meta: {
      title: "Privacy Policy"
    }
  },
  {
    path: "/refund",
    name: "Refund",
    component: () => import('../views/site/Refund.vue'),
    meta: {
      title: "Refund Policy"
    }
  },
  {
    path: "/gdrp",
    name: "GDRP",
    component: () => import('../views/site/GDPR.vue'),
    meta: {
      title: "GDRP"
    }

  },
  {
    path: "/status",
    name: "Status",
    component: () => import('../views/site/Status.vue'),
    meta: {
      title: "Status"
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: "404",
    component: () => import('../views/site/404.vue'),
    meta: {
      title: "404 Not Found"
    }
   },
]

export default routes
